import axios from 'axios';


const getAllOrg = async () => {
   const result = await axios.post("https://dp-02-db2a5.wl.r.appspot.com/internal/getOrgs")
     return result.data;
}
const createNewOrg = async (name) => {

   const result = await axios.post("https://dp-02-db2a5.wl.r.appspot.com/internal/createOrg",{
      name : name,
     })
     return result.data;

}

const getLogs = async () => {
   const result = await axios.get("https://dp-02-db2a5.wl.r.appspot.com/internal/getLogs")
     return result.data;
}

const getTransferLogs = async () => {
   const result = await axios.get("https://dp-02-db2a5.wl.r.appspot.com/internal/getTransferLogs")
     return result.data;
}




const disabledUser = async (uid) => {

   const result = await axios.post("https://dp-02-db2a5.wl.r.appspot.com/internal/disabledUser/",{
      uid : uid
     })
     return result.data;

}

const promoteUser = async (uid,role,org = null) => {

   const result = await axios.post("https://dp-02-db2a5.wl.r.appspot.com/internal/promoteUser/",{
      uid : uid,
      role : role,
      org : org
     })
     return result.data;

}


const deleteOrg = async (id) => {

   const result = await axios.post("https://dp-02-db2a5.wl.r.appspot.com/internal/deleteOrg/"+id,{
      name : name,
     })
     return result.data;

}

const updatePhoneNumber = async (phoneNum) => {
     const result = await axios.post("https://dp-02-db2a5.wl.r.appspot.com/internal/updateAdminPhoneNumber",{data:{
      phone : phoneNum,
     }
       
     })
     return result.data;
}

const resetPassword = async (email) => {
   const result = await axios.post("https://dp-02-db2a5.wl.r.appspot.com/public/resetPassword",{
      email : email,
   })
     return result.data;
}

const getDataFeed = async () => {
   const result = await axios.get("https://dp-02-db2a5.wl.r.appspot.com/internal/getAllDataFeed")
     return result.data;
}

const getAllUsers  = async () => {
   const result = await axios.get("https://dp-02-db2a5.wl.r.appspot.com/internal/getAllUsers")
     return result.data;
}
export {updatePhoneNumber, resetPassword, getDataFeed, createNewOrg, getAllOrg,deleteOrg,disabledUser,getLogs,getAllUsers,promoteUser, getTransferLogs}