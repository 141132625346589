import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import ActivityLog from "../views/ActivityLog.vue"
import Login from "../views/Login.vue"
import firebase from "firebase/compat/app";
import store from '../store'
import timesheet from '../views/timeSheet'
import Reports from '../views/Reports'
import Cases from "../views/Cases"
import AdminPage from "../views/Admin.vue"
import AdminDataFeed from "../views/AdminDataFeed.vue"
import OrgSettings from "../views/OrgSettings.vue"
import NewCase from "../views/NewCase"
import Case from "../views/Case"
import UsersPage from "../views/Users.vue"

import "firebase/auth"
import TransferLog from '../views/TransferLog.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true },
  },
  {
		path: "/login",
		name: "Login",
		component: Login,
	},
  {
    path: '/case/:id',
    name: 'Case Information',
    component: Case,
    meta: { requiresAuth: true },
  },
  {
    path: '/newCase',
    name: 'New Case Information',
    component: NewCase,
    meta: { requiresAuth: true },
  },
  {
    path: '/cases',
    name: 'All Case Information',
    component: Cases,
    meta: { requiresAuth: true },
  },
  {
    path: '/admin',
    name: 'Admin Page',
    component: AdminPage,
    meta: { requiresAuth: true , requiresAdmin: true },
  },
  {
    path: '/users',
    name: 'Users Page',
    component: UsersPage,
    meta: { requiresAuth: true , requiresAdmin: true },
  },
  {
    path: '/datafeed',
    name: 'Data Feed',
    component: AdminDataFeed,
    meta: { requiresAuth: true , requiresAdmin: true },
  },
  
  {
    path: '/timesheet',
    name: 'timesheet',
    component: timesheet,
    meta: { requiresAuth: true },
  },

 
  {
    path: '/activityLog',
    name: 'Activity Log',
    component: ActivityLog,
    meta: { requiresAuth: true },
  },
  {
    path: '/transferLog',
    name: 'Transfer Log',
    component: TransferLog,
    meta: { requiresAuth: true },
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports,
    meta: { requiresAuth: true },
  },

  {
    path: '/org-settings',
    name: 'Org Settings',
    component: OrgSettings,
    meta: { requiresAuth: true },
  },
 
 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {

  if (to.matched.some((record) => record.meta.requiresAuth)) {
		firebase.auth().onAuthStateChanged(async (user) => {
			if (user) {
				let { claims } = await firebase
					.auth()
					.currentUser.getIdTokenResult(true);
          if(!claims.appLevel.includes(2)){
            firebase
            .auth()
            .signOut()
            .then(() => {
              alert("User Not Authorized");
              location.reload();
            });
           }
           console.log(claims);
				store.commit("set_claims", claims);
				store.commit("set_role", claims.roles[2].role);
				store.dispatch("filterAdminItem", claims.role);
				store.dispatch("fetchUser", user);
        store.commit("SET_USER", user);
				if (to.meta.requiresAdmin && store.getters.claims.roles[2].role === "User") {
					next({
						path: "/",
					});
				} else {
					next();
				}
			} else {
				next({
					path: "/login",
				});
			}
		});
	} else {
		next();
	}


});


export default router
