import axios from 'axios';



const partnerGetAllUsers  = async () => {
   const result = await axios.post("https://waproject-5dc59.uw.r.appspot.com/partnerApp/getAllUsers",{
    appId : 2,
   })
     return result.data;
}
export {partnerGetAllUsers}