<template>
  <div class='ma-2'>
    <v-card-title>
      Time Entry Report
    </v-card-title>
    <v-data-table
    :loading="loading"
    :headers="headers"
    :items="timeEntry"
    item-key="index"
    class="elevation-1"
  >

  <template v-slot:[`item.ContractorId`]="{ item }">
    {{computeContractID(item.ContractorId)}}
    </template>

    <template v-slot:[`item.action`]="{ item }">
      <v-btn icon @click="removeEntry(item.RecordID)">
        <v-icon large>mdi-delete</v-icon>
      </v-btn>
    </template>

    <template v-slot:[`item.TimeSheetRecordsCaseIds.CaseId`]="{ item }">
      <v-chip :key="i" v-for="c,i in item['TimeSheetRecordsCaseIds.CaseId']">
      {{c}}</v-chip>
    </template>
    


</v-data-table>
  </div>
</template>

<script>
import { deleteTimeEntry, getTimeEntry } from '../dataservices/timeEntryDataService';
import { mapFields } from "vuex-map-fields";
import {partnerGetAllUsers} from "../dataservices/partnerDataService"


export default {
  computed: {
    ...mapFields(["role"]),
  },
  async mounted() {
   this.timeEntry =  await getTimeEntry()
   this.contractors = await partnerGetAllUsers();
   this.loading = false;
   if( this.role !== "User"){
    this.headers.push({ text: 'Action (Admin Only)', value: 'action' })
   }
 
},
methods : {
 async removeEntry(id){
    await deleteTimeEntry(id);
    location.reload();
  },
  computeContractID(id){
    const name = this.contractors.find(c => c['User_uu.User_uuid'] === id);
    return name ? name['User_uu.FirstName'] + " " + name['User_uu.LastName'] : "";
  },
},
  data() {
    return {
      loading : true,
      contractors : [],
      timeEntry : [],
      headers: [
        { text: 'Date', value: 'Date' },
        { text: 'Time Entry User', value: 'ContractorId' },
        { text: 'User Email', value: 'UserEmail' },
        { text: 'Type', value: 'Type' },
        { text: 'Hours', value: 'hrs' },
        { text: 'Minutes', value: 'mins' },
        { text: 'Case', value: 'TimeSheetRecordsCaseIds.CaseId' },
        { text: 'Comment', value: 'Comment' },
      ],
    }
  }
};
</script>

<style>
</style>